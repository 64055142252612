<template>
  <section class="invoice-preview-wrapper">
    <b-alert variant="danger" class="text-center" :show="reservationData === undefined">
      <h4 class="alert-heading">Rezervasyon verileri alınırken hata oluştu</h4>
      <div class="alert-body">
        Bu rezervasyon kimliğine sahip rezervasyon bulunamadı. <br />
        Diğer rezervasyonlar için
        <b-link class="alert-link" :to="{ name: 'rezervasyonlar' }"> Rezervasyonlar listesine gidin. </b-link>
      </div>
    </b-alert>

    <b-row v-if="Object.keys(reservationData).length > 0" class="invoice-preview">
      <b-col cols="12" xl="8" md="7">
        <vue-html2pdf
          :show-layout="true"
          :float-layout="false"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :filename="reservationData.k_no"
          :pdf-quality="3"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="landscape"
          pdf-content-width="100%"
          ref="html2Pdf"
          :margin="40"
        >
          <section slot="pdf-content" id="printMe">
            <b-card no-body class="invoice-preview-card rounded-0">
              <b-card-body class="invoice-padding pb-0">
                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                  <div>
                    <div class="logo-wrapper">
                      <h3 class="text-primary invoice-logo">SİDE TURKEY</h3>
                    </div>
                    <p class="card-text mb-25">Side Street No: 509</p>
                    <p class="card-text mb-25">Manavgat Antalya TÜRKİYE ZIP 07330</p>
                    <p class="card-text mb-0">+90 536 603 75 84, muratatalay0721@gmail.com</p>
                  </div>
                  <div class="mt-md-1 mt-4">
                    <h4 class="invoice-title">
                      Rez No:
                      <span class="invoice-number">#{{ reservationData.k_no }}</span>
                    </h4>

                    <div class="invoice-date-wrapper d-block text-right">
                      İşlem Tarihi:
                      <strong>{{ reservationData.save_date | momentFull }}</strong>
                    </div>
                    <div class="invoice-date-wrapper d-block text-right">
                      Rezervasyon tarihi:
                      <strong>{{ reservationData.tarih }}</strong>
                    </div>
                    <div class="invoice-date-wrapper d-block text-right" v-if="reservationData.seans_alis_saat">
                      Rezervasyon Seans:
                      <strong>{{ reservationData.seans_alis_saat }}</strong>
                    </div>
                    <div class="invoice-date-wrapper d-block text-right">
                      Rezervasyon Durum:
                      <b-badge class="rounded-0" :style="`background-color:${statuColor(reservationData.statu)};`">
                        {{ statuText(reservationData.statu) }}
                      </b-badge>
                    </div>
                  </div>
                </div>
              </b-card-body>
              <hr class="invoice-spacing" />
              <b-card-body class="invoice-padding pt-0">
                <b-row class="invoice-spacing">
                  <b-col cols="12" xl="6" class="p-0">
                    <h6 class="mb-2">Rezervasyon Bilgi:</h6>
                    <h6 class="mb-25">{{ reservationData.ad }} {{ reservationData.soyad }}</h6>
                    <p class="card-text mb-25">{{ reservationData.e_mail }} - {{ reservationData.telefon }}</p>
                    <p class="card-text mb-25">{{ reservationData.adres }}</p>
                    <p class="card-text mb-25">
                      {{ reservationData.sehir }} -{{ reservationData.ulke }} - {{ reservationData.postaKodu }}
                    </p>
                  </b-col>

                  <!-- Col: Payment Details -->
                  <b-col xl="6" cols="12" class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end">
                    <div>
                      <h6 class="mb-2">Ödeme Bilgisi:</h6>
                      <table>
                        <tbody>
                          <tr>
                            <td class="pr-1">Ödeme Yöntemi:</td>
                            <td>
                              <span class="font-weight-bold">{{ odemeText(reservationData.odeme_yontemi) }} </span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1">Ödeme Durumu:</td>
                            <td>
                              <span
                                class="font-weight-bold"
                                :class="reservationData.odeme_durum ? 'text-success' : 'text-danger'"
                              >
                                {{ reservationData.odeme_durum ? 'Tahsil Edildi' : 'Beklemede' }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
              <table class="table table-bordered">
                <thead class="table-alert-info">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">#Urun K No</th>
                    <th scope="col">Urun Adı</th>
                    <th scope="col">Pax</th>
                    <th scope="col">Fiyat</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>1</th>
                    <td style="padding: 0px 5px">
                      {{ reservationData.urun_k_no }}
                    </td>
                    <td style="padding: 0px 5px">
                      {{ reservationData.urun[0].urun_adi | str_limit(30) }}
                    </td>
                    <td style="padding: 0px 5px">
                      <b-badge fill variant="primary" v-b-tooltip.hover.top="'Yetişkin'" class="rounded-0 mr-25">
                        {{ reservationData.yetiskin_pax }}
                      </b-badge>
                      <b-badge fill variant="danger" v-b-tooltip.hover.top="'Çocuk'" class="rounded-0 mr-25">
                        {{ reservationData.cocuk_pax }}
                      </b-badge>
                      <b-badge fill variant="success" v-b-tooltip.hover.top="'Bebek'" class="rounded-0 mr-25">
                        {{ reservationData.bebek_pax }}
                      </b-badge>
                    </td>
                    <td class="text-right" style="padding: 0px">
                      <b-badge
                        fill
                        variant="primary"
                        v-b-tooltip.hover.top="'Yetişkin'"
                        class="rounded-0 mr-25"
                        v-if="reservationData.yetiskin_pax > 0"
                      >
                        {{ reservationData.yetiskin_fiyat | cur }} {{ reservationData.secilen_kur }}
                      </b-badge>
                      <b-badge
                        fill
                        variant="danger"
                        v-b-tooltip.hover.top="'Çocuk'"
                        class="rounded-0 mr-25"
                        v-if="reservationData.cocuk_pax > 0"
                      >
                        {{ reservationData.cocuk_fiyat | cur }} {{ reservationData.secilen_kur }}
                      </b-badge>
                      <b-badge
                        fill
                        variant="success"
                        v-b-tooltip.hover.top="'Bebek'"
                        class="rounded-0 mr-25"
                        v-if="reservationData.bebek_pax > 0"
                      >
                        {{ reservationData.bebek_fiyat | cur }} {{ reservationData.secilen_kur }}
                      </b-badge>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-card-body class="p-0 pb-2">
                <b-row class="invoice-spacing">
                  <b-col cols="12" xl="6" class="p-0">
                    <h6 class="mb-1 ml-1">Hotel Bilgisi:</h6>
                    <h6 class="mb-1 ml-1">
                      Hotel Adı : <b class="text-primary">{{ reservationData.hotel_adi }}</b>
                    </h6>
                    <h6 class="mb-1 ml-1">
                      Hotel Oda No: <b class="text-primary"> {{ reservationData.hotel_oda_no }}</b>
                    </h6>
                    <h6 class="mb-1 ml-1">
                      Hotel Adres: <b class="text-primary"> {{ reservationData.hotel_adres }}</b>
                    </h6>
                    <h6 class="mb-1 ml-1">Rezervasyon Notu:</h6>
                    <p class="card-text mb-25 ml-1">{{ reservationData.rezervasyon_notu }}</p>
                  </b-col>

                  <!-- Col: Payment Details -->
                  <b-col xl="6" cols="12" class="p-0 mt-xl-0 d-flex justify-content-xl-end">
                    <table class="table" style="max-width: 400px; border: none">
                      <tbody>
                        <tr>
                          <td>Ara Toplam:</td>
                          <td class="h5 text-right">
                            {{ reservationData.secilen_kur_toplam | cur }} {{ reservationData.secilen_kur }}
                          </td>
                        </tr>
                        <tr>
                          <td>Kur Birim Fiyat:</td>
                          <td class="h5 text-right">
                            1 {{ reservationData.secilen_kur }} x {{ reservationData.secilen_kur_fiyat | cur }} TRY
                          </td>
                        </tr>
                        <tr>
                          <td>İndirim:</td>
                          <td class="h5 text-right">0.00 {{ reservationData.secilen_kur }}</td>
                        </tr>
                        <tr>
                          <td>Genel Toplam:</td>
                          <td class="h5 text-right">
                            {{ reservationData.secilen_kur_toplam | cur }} {{ reservationData.secilen_kur }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </section>
        </vue-html2pdf>
      </b-col>

      <b-col cols="12" md="5" xl="4" class="invoice-actions">
        <b-card class="rounded-0">
          <b-button
            v-b-toggle.sidebar-send-invoice
            variant="warning"
            class="mb-75"
            block
            squared
            :to="{
              name: 'rezervasyonlar',
            }"
          >
            Geri Git
          </b-button>
          <b-button variant="primary" class="mb-75" block squared @click="handlerBiletYazdir"> Bilet Gönder </b-button>
          <b-button squared variant="outline-secondary" class="mb-75" block @click="handlerDownloadToPdf"> İndir </b-button>
          <b-button squared variant="outline-secondary" class="mb-75" block @click="printInvoice"> Yazdır </b-button>
          <b-button squared variant="outline-secondary" class="mb-75" block v-b-toggle.rezervasyon-guncelle> Düzenle </b-button>
          <b-button
            squared
            variant="outline-danger"
            class="mb-75"
            :disabled="reservationData.statu != 5"
            block
            @click="handlerRemove(reservationData.k_no)"
          >
            Sil
          </b-button>
          <b-row>
            <b-col cols="12" md="6">
              <b-dropdown id="dropdown-1" text="Rezervasyon Durum" variant="success" block class="rounded-0">
                <b-dropdown-item v-for="item in statuData" :key="item.id" @click="handlerStatuDurum(item.id)">
                  <span class="d-block" :style="`color:${statuColor(item.id)};`">
                    {{ statuText(item.id) }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col cols="12" md="6">
              <b-dropdown id="dropdown-1" text="Ödeme Durum" variant="info" block class="rounded-0">
                <b-dropdown-item v-for="item in odemeData" :key="item.id" @click="handlerOdemeDurum(item.id)">
                  <span class="d-block" :style="`color:${odemeTahsilColor(item.id)};`">{{ item.title }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="rounded-0">
          <b-card-title> İşlem Geçmişi </b-card-title>
          <hr class="p-0 m-0" />
          <b-card-text>
            <vue-perfect-scrollbar ref="refChatLogPS" :settings="perfectScrollbarSettings" class="scroll-area">
              <b-table small striped responsive hover :items="reservationData.islem_gecmisi" :fields="fields">
                <template #cell(tarih)="data">
                  {{ data.tarih | momentFull }}
                </template>
              </b-table>
            </vue-perfect-scrollbar>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <rezervasyon-guncelle :rezervasyon="reservationData" :urunData="urunData" />
    <app-bilet :openModal="openModal" :updateData="updateData" @closeModal="closeModal = $event" />
  </section>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import RezervasyonGuncelle from './rezervasyonGuncelle.vue';
import VueHtml2pdf from 'vue-html2pdf';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import AppBilet from './component/bilet.vue';
export default {
  components: {
    RezervasyonGuncelle,
    VueHtml2pdf,
    VuePerfectScrollbar,
    AppBilet,
  },
  data: () => ({
    show: false,
    openModal: false,
    closeModal: false,
    updateData: {},
    reservationData: {},
    urunData: {},
    statuData: [
      { id: 1, title: 'Onay Bekliyor' },
      { id: 2, title: 'Değişiklik İsteği' },
      { id: 3, title: 'Ücret İade İsteği' },
      { id: 4, title: 'Onaylandı' },
      { id: 5, title: 'İptal Edildi' },
    ],
    odemeData: [
      { id: true, title: 'Tahsil Edildi' },
      { id: false, title: 'Beklemede' },
    ],
    perfectScrollbarSettings: {
      maxScrollbarLength: 150,
      wheelPropagation: false,
    },
    fields: [
      { key: 'tarih', label: 'Tarih' },
      { key: 'aciklama', label: 'Açıklama' },
      { key: 'user', label: 'Kullanıcı' },
    ],
  }),
  computed: {
    statuText() {
      const statusText = {
        1: 'Onay Bekliyor',
        2: 'Değişiklik İsteği',
        3: 'Ücret İade İsteği',
        4: 'Onaylandı',
        5: 'İptal Edildi',
      };

      return (status) => statusText[status];
    },
    statuColor() {
      const statusColor = {
        1: '#1377d3',
        2: '#ffc107',
        3: '#DA4E8F',
        4: '#20c997',
        5: '#ea5455',
      };

      return (status) => statusColor[status];
    },
    odemeText() {
      const statusText = {
        1: 'Kapıda Ödeme',
        2: 'Havale EFT',
        3: 'Kredi Kartı',
      };

      return (status) => statusText[status];
    },
    odemeColor() {
      const statusColor = {
        1: '#1377d3',
        2: '#20c997',
        3: '#ff9f43',
      };

      return (status) => statusColor[status];
    },

    odemeTahsilColor() {
      const statusColor = {
        true: '#20c997',
        false: '#ea5455',
      };

      return (status) => statusColor[status];
    },
  },
  async created() {
    if (this.$route.params.k_no) {
      this.show = true;
      await this.$store.dispatch('handlerRezervasyonFindOne', this.$route.params.k_no).then((res) => {
        this.reservationData = res.data.data;
      });

      if (this.reservationData) {
        await this.$store.dispatch('handlerUrunFindOne', this.reservationData.urun_k_no).then((res) => {
          this.urunData = res.data.data;
        });
      }
      this.show = false;
    }
  },
  methods: {
    printInvoice() {
      window.print();
    },
    handlerDownloadToPdf() {
      this.$refs.html2Pdf.generatePdf();
    },
    handlerStatuDurum(event) {
      const k_no = this.reservationData.k_no;
      this.$store.dispatch('rezervasyonStatuDurum', { k_no, statu: event }).then((res) => {
        if (res.data.success == true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Bilgi`,
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'Güncelleme Başarılı',
            },
          });
          this.reservationData.islem_gecmisi = res.data.data.islem_gecmisi;
          this.reservationData.statu = event;
        }
      });
    },
    handlerOdemeDurum(event) {
      const k_no = this.reservationData.k_no;
      this.$store.dispatch('rezervasyonOdemeDurum', { k_no, odeme_durum: event }).then((res) => {
        if (res.data.success == true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Bilgi`,
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'Güncelleme Başarılı',
            },
          });
          this.reservationData.islem_gecmisi = res.data.data.islem_gecmisi;
          this.reservationData.odeme_durum = event;
        }
      });
    },
    handlerRemove(k_no) {
      this.$swal({
        title: 'Uyarı',
        text: 'Rezervasyona ait tüm veriler silinecektir. Silmek istediğinizden eminmisiniz!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.$store.dispatch('rezervasyonSil', k_no).then((res) => {
            if (res.data.success === true) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Silme`,
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: `Ekleme başarılı.`,
                },
              });
            }
          });

          this.$router.push({ name: 'rezervasyonlar' });
        }
      });
    },

    handlerBiletYazdir() {
      this.updateData = this.reservationData;

      this.openModal = true;
    },
  },
  watch: {
    closeModal(val) {
      if (val) {
        this.openModal = false;
        this.closeModal = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
.scroll-area {
  position: relative;
  height: 400px;
}
</style>

<style lang="scss">
.btn {
  border-radius: 0rem;
}
.dropdown-menu {
  border-radius: 0rem;
}
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
