<template>
  <b-sidebar id="rezervasyon-guncelle" sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right>
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2">
        <h5 class="mb-0">Rezervasyon Güncelle</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-form-group label="Rezervasyon tarihi">
          <flat-pickr v-model="rezervasyon.tarih" placeholder="Bu tarihle" class="form-control rounded-0" :config="config" />
        </b-form-group>

        <b-form-group label="Seans Alış Saati" v-if="rezervasyon.seans_alis_saat">
          <v-select
            v-model="rezervasyon.seans_alis_saat"
            :options="urunData.seans_alis_saat"
            :reduce="(urunData) => urunData"
            :clearable="false"
            class="invoice-filter-select d-block"
          />
        </b-form-group>

        <b-form-group label="Hotel Adı">
          <b-form-input v-model="rezervasyon.hotel_adi" class="rounded-0" />
        </b-form-group>

        <b-form-group label="Hotel Oda Numarası">
          <b-form-input v-model="rezervasyon.hotel_oda_no" class="rounded-0" />
        </b-form-group>

        <b-form-group label="Rezervasyon Notu">
          <b-form-textarea rows="2" v-model="rezervasyon.rezervasyon_notu" class="rounded-0" />
        </b-form-group>

        <b-form-group label="Rezervasyon Durum">
          <v-select
            v-model="rezervasyon.statu"
            :options="Statuler"
            :reduce="(statu) => statu.value"
            label="title"
            :clearable="false"
            class="invoice-filter-select d-block"
          />
        </b-form-group>

        <b-form-group label="Ödeme Durum">
          <v-select
            v-model="rezervasyon.odeme_durum"
            :options="OdemeDurum"
            :reduce="(odeme) => odeme.value"
            label="title"
            :clearable="false"
            class="invoice-filter-select d-block"
          />
        </b-form-group>

        <div class="d-flex mt-2">
          <b-button squared variant="warning" class="mr-2" type="submit" @click="handlerUpdate"> Güncelle </b-button>
          <b-button squared variant="outline-secondary" @click="hide"> Cancel </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
export default {
  components: {
    flatPickr,
    vSelect,
  },
  props: {
    rezervasyon: {
      type: Object,
      required: true,
    },
    urunData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    config: {
      events: ['onChange', 'onClose', 'onDayCreate', 'onStartChange'],
      inline: false,
      mode: 'single',
      dateFormat: 'd.m.Y',
      locale: Turkish, // locale for this instance only
    },
    Statuler: [
      { title: 'Onay Bekliyor', value: 1 },
      { title: 'Değişiklik İsteğine Al', value: 2 },
      { title: 'Ücret İade İsteği Al', value: 3 },
      { title: 'Onaylandı', value: 4 },
      { title: 'İptal Edildi', value: 5 },
    ],
    OdemeDurum: [
      { title: 'Tahsil Edildi', value: true },
      { title: 'Beklemede', value: false },
    ],
  }),
  methods: {
    handlerUpdate() {
      this.$store
        .dispatch('rezervasyonGuncelle', this.rezervasyon)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Bilgi`,
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'Güncelleme Başarılı',
            },
          });
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
